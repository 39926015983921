import React, { Component } from 'react';
import SeatAvailability from './SeatAvailability';
import { Collapse } from 'react-bootstrap';
// import {   } from 'react-bootstrap/Popover';
import ClassDetails from './ClassDetails';
import isEqual from 'lodash.isequal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import RelatedClasses from './RelatedClasses';
import UtilFunctions from '../UtilFunctions';
import { spinnerService } from '@simply007org/react-spinners';
import serviceauth from '../auth/serviceauth';

class ClassRow extends Component {
  constructor(props) {
    super();
    var detailsOpen,
      relatedClassesOpen = false;
    var crseId = undefined;
    var hash = window.location.hash;
    if (hash !== '') {
      var myHashArray = hash.split('=');
      if (
        myHashArray[0].indexOf('detailsOpen') >= 0 ||
        myHashArray[0].indexOf('relatedClassOpen') >= 0
      ) {
        var ids = myHashArray[1].split('-');
        if (ids[0] === props.classItem.CLAS.CLASSNBR) {
          crseId = ids[1];
          if (myHashArray[0].indexOf('detailsOpen') >= 0) {
            detailsOpen = true;
          } else {
            relatedClassesOpen = true;
          }
        }
      }
    }

    this.state = {
      detailsOpen: detailsOpen,
      relatedClassesOpen: relatedClassesOpen,
      showMoreInfo:
        props.showMoreInfo !== undefined ? props.showMoreInfo : false, //false when opening row in drawer
      crseId: crseId,
      hash: hash !== '' ? hash.replace('#', '') : '',
      modalShow: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleRelatedClassesClick = this.handleRelatedClassesClick.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRowKeyDown = this.handleRowKeyDown.bind(this);
    this.handleAddClassClick = this.handleAddClassClick.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleModalHide = this.handleModalHide.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.classItem, this.props.classItem)) {
      // console.log(" componentDidUpdate: closing drawers" )
      this.setState({
        detailsOpen: false,
        relatedClassesOpen: false,
      });
    }
  }

  componentDidMount() {
    if (this.state.hash !== '') {
      if (document.getElementById(this.state.hash))
        document.getElementById(this.state.hash).scrollIntoView();
    }
  }

  handleClick(e, crseId) {
    if (e.key === undefined || e.key === 'Enter') {
      this.setState(
        {
          detailsOpen: !this.state.detailsOpen,
          crseId: crseId,
          relatedClassesOpen: false,
        },
        () => {
          if (this.state.detailsOpen)
            window.history.pushState(
              '',
              '',
              '#detailsOpen=' +
                this.props.classItem.CLAS.CLASSNBR +
                '-' +
                this.props.classItem.CLAS.CRSEID
            );
          else {
            window.history.pushState(
              '',
              '',
              window.location.href.split('#')[0]
            );
          }
        }
      );
    }
  }

  handleRelatedClassesClick(e, crseId) {
    this.setState(
      {
        relatedClassesOpen: !this.state.relatedClassesOpen,
        crseId: crseId,
        detailsOpen: false,
      },
      () => {
        if (this.state.relatedClassesOpen)
          //show spinner only when drawer is opening
          spinnerService.show('mySpinner');

        if (this.state.relatedClassesOpen)
          window.history.pushState(
            '',
            '',
            '#relatedClassesOpen=' +
              this.props.classItem.CLAS.CLASSNBR +
              '-' +
              this.props.classItem.CLAS.CRSEID
          );
        else {
          window.history.pushState('', '', window.location.href.split('#')[0]);
        }
      }
    );
  }

  // async handleInstructorClick(e, classNbr, firstName, lastName) {
  //     //alert(classNbr)
  //     e.preventDefault();
  //     // var url = await instructor.getInstructorIsearchProfile(this.props.classItem.CLAS.STRM, classNbr, firstName, lastName)
  //    window.open("/instructor?strm="+this.props.classItem.CLAS.STRM+"&classNbr="+classNbr+"&firstName="+firstName+"&lastName="+lastName)
  //    //this.props.history.push(this.props.match.path )

  // }

  handleRowClick(e) {
    if (this.props.onClick !== undefined)
      this.props.onClick(
        e,
        this.props.classItem,
        this.props.classComponentTypeIndex
      );
  }

  handleRowKeyDown(e) {
    if (e.keyCode === 13 && this.props.onClick !== undefined) {
      this.props.onClick(
        e,
        this.props.classItem,
        this.props.classComponentTypeIndex
      );
    }
  }

  handleAddClassClick(e, term, classNbr, relClass1, relClass2) {
    var url = '';
    if (relClass1 === undefined) url = getAddClassUrl(term, classNbr);
    else url = getAddRelatedClassesUrl(term, classNbr, relClass1, relClass2);

    // alert(url)
    window.location.href = url;
  }

  handleModalShow() {
    this.setState({
      modalShow: true,
    });
  }

  handleModalHide() {
    this.setState({
      modalShow: false,
    });
  }

  render() {
    const classItem = this.props.classItem;
    const isPastEnrollmentDeadline = UtilFunctions.isPastEnrollmentDeadline(
      classItem.CLAS.ENROLLDEADLINE
    );

    var addButton = [];

    if (classItem.CLAS.PASSTERM === 'Y') {
      // if (classItem.CLAS.ENROLLDEADLINE && new Date(classItem.CLAS.ENROLLDEADLINE) < new Date()) { past date

      // } else { not past date

      // }
      // console.log(new Date(classItem.CLAS.ENROLLDEADLINE) + " == " + currentDate)
      if (
        classItem.CLAS.SSRCOUNT > 1 ||
        classItem.CLAS.ASSOCIATEDCLASS == null ||
        classItem.CLAS.ASSOCIATEDCLASS === ''
      ) {
        addButton.push(
          <button
            type="button"
            className="btn btn-maroon btn-md btn-lg-block mt-2 mb-2 mt-lg-0 mb-lg-0"
            onClick={(e) =>
              this.handleRelatedClassesClick(e, classItem.CLAS.CRSEID)
            }
          >
            Select
          </button>
        );
      } else {
        if (isPastEnrollmentDeadline) {
          addButton.push(
            // <OverlayTrigger trigger="click" placement="left" overlay={UtilFunctions.getPopover(classItem)} rootClose={true}>
            <button
              type="button"
              className="btn btn-gray btn-md text-nowrap btn-block mt-1 mb-1 mt-lg-0 mb-lg-0"
              onClick={this.handleModalShow}
            >
              Info
            </button>
            // </OverlayTrigger>
          );
          addButton.push(
            UtilFunctions.getLateAddModal(
              classItem,
              this.state.modalShow,
              this.handleModalHide
            )
          );
        } else {
          addButton.push(
            <button
              type="button"
              className="btn btn-md btn-maroon mt-1 mb-1 mt-lg-0 mb-lg-0"
              onClick={(e) =>
                this.handleAddClassClick(
                  e,
                  classItem.CLAS.STRM,
                  classItem.CLAS.CLASSNBR
                )
              }
            >
              Add
            </button>
          );
        }
      }
    }
    const oddEvenClass = this.props.oddEvenClass ? 'odd' : 'even';

    return (
      <React.Fragment key={`fragment-${classItem.CLAS.CLASSNBR}`}>
        <div
          onClick={(e) => this.handleRowClick(e, classItem.CLAS.CRSEID)}
          className={`focus class-accordion ${oddEvenClass}`}
          tabIndex={0}
          onKeyDown={(e) => this.handleRowKeyDown(e, classItem.CLAS.CRSEID)}
        >
          {/*//this will show only on mobile and medium. For desktop, check the same block below
                        this.props.showCaret &&
                        <>
                            <div className="d-lg-none pull-right me-1 pointer">
                                <i className="glyphicon glyphicon-user" onClick={this.props.cancelRelatedClasses}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </i>
                            </div>
                            { <div className="d-lg-none pull-right me-3 pointer">
                                <i className="glyphicon glyphicon-user">
                                    <FontAwesomeIcon icon={faSort} />
                                </i></div> }
                            
                        </>
                     */}

          {!this.state.showMoreInfo && (
            <>
              {/* table view. Done separately so we can combine the hyperlinksCSCC-227 */}
              <div
                onClick={(e) => this.handleClick(e, classItem.CLAS.CRSEID)}
                onKeyDown={(e) => this.handleClick(e, classItem.CLAS.CRSEID)}
                className="class-results-cell course pointer text-nowrap  pe-1 pull-left d-none d-lg-inline border-focus"
                tabIndex={0}
              >
                {!this.state.showMoreInfo && (
                  <i className="glyphicon glyphicon-user form-control-feedback pe-2 d-none d-lg-inline ">
                    <FontAwesomeIcon
                      icon={
                        this.state.detailsOpen ? faChevronDown : faChevronRight
                      }
                    />
                  </i>
                )}
                <span className="bold-hyperlink ">
                  {classItem.SUBJECTNUMBER}
                </span>
              </div>
              {/* table view. Done separately so we can combine the hyperlinks  CSCC-227*/}
              <div
                onClick={(e) => this.handleClick(e, classItem.CLAS.CRSEID)}
                onKeyDown={(e) => this.handleClick(e, classItem.CLAS.CRSEID)}
                className="class-results-cell pointer  title d-none d-lg-inline border-focus"
                tabIndex={0}
              >
                <span className="bold-hyperlink ">{getTitle(classItem)}</span>
              </div>

              {/* accordion view. Done separately so we can combine the hyperlinks CSCC-227 */}
              <div
                onClick={(e) => this.handleClick(e, classItem.CLAS.CRSEID)}
                className="class-results-cell course pointer  pe-1  d-lg-none"
              >
                <span className="bold-hyperlink">
                  {classItem.SUBJECTNUMBER} {getTitle(classItem)}
                </span>
                {!this.state.showMoreInfo && (
                  <i className="glyphicon glyphicon-user form-control-feedback pe-2 pull-right">
                    <FontAwesomeIcon
                      icon={
                        this.state.detailsOpen ? faChevronUp : faChevronDown
                      }
                    />
                  </i>
                )}
              </div>
            </>
          )}

          {this.state.showMoreInfo && (
            <div className="class-results-cell">
              {classItem.CLAS.COMPONENTPRIMARY}
            </div>
          )}

          <div
            className="class-results-cell number"
            id={
              'detailsOpen=' +
              classItem.CLAS.CLASSNBR +
              '-' +
              classItem.CLAS.CRSEID
            }
          >
            <div>{classItem.CLAS.CLASSNBR}</div>
            <>
              {' '}
              {classItem.HASSYLLABUS && (
                <a
                  href={`https://webapp4.asu.edu/bookstore/viewsyllabus/${classItem.CLAS.STRM}/${classItem.CLAS.CLASSNBR}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="float-lg-start"
                >
                  Syllabus
                </a>
              )}
            </>
          </div>

          <div
            className="class-results-cell instructor"
            id={
              'relatedClassesOpen=' +
              classItem.CLAS.CLASSNBR +
              '-' +
              classItem.CLAS.CRSEID
            }
          >
            {getInstructor(classItem)}
          </div>

          {getDaysAndDates(classItem)}

          {getLocation(classItem, (e) =>
            this.handleClick(e, classItem.CLAS.CRSEID)
          )}

          <div className="class-results-cell d-none d-lg-block dates">
            <Dates
              dates={classItem.CLAS.MEETINGDATES}
              sessioncode={classItem.CLAS.SESSIONCODE}
            />
          </div>
          <div className="class-results-cell d-none d-lg-block units">
            {getUnits(classItem)}
          </div>
          <div className="class-results-cell seats">
            <SeatAvailability
              classItem={classItem}
              seatInfo={classItem.seatInfo}
              cohortCountInfo={classItem.cohortCountInfo}
              reservedSeatsInfo={classItem.reservedSeatsInfo}
              isRelatedClass={this.props.isRelatedClass}
              onClick={this.handleClick}
            />
          </div>
          {/* <div className="class-results-cell syllabus">
                        {
                            classItem.HASSYLLABUS &&
                            <a
                                href={`https://webapp4.asu.edu/bookstore/viewsyllabus/${classItem.CLAS.STRM}/${classItem.CLAS.CLASSNBR}`}
                                target="_blank" rel="noopener noreferrer">Syllabus
                            </a>
                        }
                    </div> */}
          <div className="class-results-cell text-nowrap gs">
            {UtilFunctions.getGSDesignationsDisplay(
              classItem.GSMAROON,
              classItem.GSGOLD
            )}
          </div>
          {!this.state.showMoreInfo && (
            <div className="class-results-cell d-none d-lg-block">
              {addButton}
            </div>
          )}
          {/*//this will show only on desktop. For mobile, check the same block above
                        this.props.showCaret &&
                        <>
                            {<div className="d-none d-lg-block pointer">
                                <i className="glyphicon glyphicon-user">
                                    <FontAwesomeIcon icon={faSort} />
                                </i></div> }
                            <div className="d-none d-lg-block pointer ">
                                <i className="glyphicon glyphicon-user pull-right" onClick={this.props.cancelRelatedClasses}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </i>
                            </div>
                        </>
                   */}
        </div>
        {!this.state.showMoreInfo && (
          <>
            <Collapse in={this.state.detailsOpen}>
              <div className={`class-accordion-dropdown ${oddEvenClass}`}>
                <div className="d-lg-none">{addButton}</div>
                {this.state.detailsOpen && (
                  <ClassDetails
                    crseId={this.state.crseId}
                    classItem={classItem}
                    detailsOpen={this.state.detailsOpen}
                  />
                )}
              </div>
            </Collapse>
            <Collapse in={this.state.relatedClassesOpen}>
              <div className={`related-class-dropdown pt-3 ${oddEvenClass}`}>
                {this.state.relatedClassesOpen && (
                  <RelatedClasses
                    crseId={this.state.crseId}
                    searchType={this.props.searchType}
                    classItem={classItem}
                    relatedClassesOpen={this.state.relatedClassesOpen}
                    onClick={this.handleAddClassClick}
                  />
                )}
              </div>
            </Collapse>
          </>
        )}
      </React.Fragment>
    );
  }
}

export default ClassRow;

export function getInstructorUrl(asurite) {
  return 'https://search.asu.edu/profile/' + asurite;
}

function Dates(props) {
  if (props.dates == null) return '';

  let output = props.dates.map((date, i) => {
    let d1 = date[0].split('/'); //removing year from dates
    let d2 = date[1].split('/');
    let sessionCode =
      props.sessioncode !== 'DYN' ? ' (' + props.sessioncode + ')' : '';

    return (
      <p className="mb-0 text-nowrap" key={i}>
        {d1[0] + '/' + d1[1] + ' - ' + d2[0] + '/' + d2[1] + sessionCode}
      </p>
    );
  });

  return output;
}

function getAddClassUrl(term, classNbr) {
  const host = UtilFunctions.ENVIRONMENT;
  var type = 'addclass';
  const asuComponent = sessionStorage.getItem(serviceauth.SS_ASU_COMPONENT);
  const swapClass = sessionStorage.getItem(serviceauth.SS_ASU_SWAP_CLASS);
  if (asuComponent && asuComponent === 'S') type = 'swapclass';

  var psSwapClass = '';
  if (swapClass && swapClass !== '')
    psSwapClass = '&ASU_SWAP_CLASS=' + swapClass;

  var link =
    'https://www' +
    host +
    '.asu.edu/go/' +
    type +
    '/?STRM=' +
    term +
    '&ASU_CLASS_NBR=' +
    classNbr +
    psSwapClass;

  return link;
}

function getAddRelatedClassesUrl(term, classNbr, relClass1, relClass2) {
  var link =
    getAddClassUrl(term, classNbr) +
    '&relClass1=' +
    relClass1 +
    '&relClass2=' +
    relClass2;
  return link;
}

function getTitle(classItem) {
  // console.log("classItem.CLAS.COMPONENTPRIMARY "+classItem.CLAS.COMPONENTPRIMARY)
  // console.log("classItem.CLAS.SSRCOUNT "+classItem.CLAS.SSRCOUNT)
  // console.log("classItem.CLAS.ASSOCIATEDCLASSNOTNULL "+classItem.CLAS.ASSOCIATEDCLASSNOTNULL)
  var title = [];
  if (classItem.CLAS.CLASSTYPE === 'E') {
    if (classItem.CLAS.DESCR50 !== '') {
      title.push(<>Topic: {classItem.CLAS.DESCR50}</>);
    } else title.push(<>{classItem.CLAS.COURSETITLELONG}</>);
    // if(classItem.CLAS.COMPONENTPRIMARY !== 'LEC'){//removed per Cat's request
    //     title.push(<> ({classItem.CLAS.COMPONENTPRIMARY})</>)
    // }
  } else {
    title.push(<>{classItem.CLAS.DESCR2}</>);
  }

  return title;
}

export function getInstructorList(classItem) {
  var instructor = [];
  if (classItem.CLAS.INSTRUCTORSLASTNAMELIST !== null) {
    for (
      let index = 0;
      index < classItem.CLAS.INSTRUCTORSLASTNAMELIST.length;
      index++
    ) {
      const lastName =
        classItem.CLAS.INSTRUCTORSLASTNAMELIST[index].split('~')[0];
      const firstName =
        classItem.CLAS.INSTRUCTORSFIRSTNAMELIST[index].split('~')[0];
      const asurite =
        classItem.CLAS.INSTRUCTORSFIRSTNAMELIST[index].split('~')[1];

      var comma = instructor.length > 0 ? ', ' : '';
      // console.log(lastName === "Staff")
      if (
        (lastName === 'Staff' && classItem.CLAS.INSTRUCTORSLIST.length === 1) ||
        lastName === ''
      )
        instructor.push(<span key={index}>{comma}Staff</span>);
      else if (lastName === 'Select instructor during enrollment')
        instructor.push(
          <span key={index}>{comma}Select instructor during enrollment</span>
        );
      else if (lastName !== 'Staff')
        instructor.push(
          <>
            {comma}
            <a
              key={index}
              href={getInstructorUrl(asurite)}
              target="_blank"
              rel="noopener noreferrer"
              className="link-color"
            >
              {firstName} {lastName}
            </a>
          </>
        );
    }
  }

  return instructor;
}

function getInstructor(classItem) {
  var instructorList = [];

  var instructor = getInstructorList(classItem);

  if (instructor.length > 0) {
    //desktop
    if (instructor.length > 1) {
      //if there are multiple instructors, we have to hide them on small and medium devices
      instructorList.push(
        <span className="d-none d-lg-block">{instructor}</span>
      );

      //if there are multiple instructors, we want to have a dropdown on the instructor list
      instructorList.push(
        <span className="d-lg-none">Multiple instructors</span>
      );
      return instructorList;
    } else return instructor;
  }

  //if inst list is empty
  if (instructor.length === 0 && classItem.CLAS.INSTRUCTOREDIT !== 'C')
    return <>Staff</>;

  //if inst list is empty
  if (instructor.length === 0 && classItem.CLAS.INSTRUCTOREDIT === 'C')
    return <>Select instructor during enrollment</>;
}

function getDaysAndDates(classItem) {
  var daysAndDates = [];

  var hideClass = '';
  if (classItem.CLAS.STARTTIMES.length > 1)
    //if there are multiple patterns, we have to hide them on small and medium devices
    hideClass = 'd-none d-lg-block';

  if (classItem.CLAS.STARTTIMES.length > 1) {
    daysAndDates.push(
      // <div onClick={clickCallBack} className="class-results-cell pointer bold-hyperlink days d-lg-none">
      <div className="class-results-cell  days d-lg-none">
        Multiple dates and times
        {/* {
                    !showMoreInfo &&
                    <i className="glyphicon glyphicon-user form-control-feedback pe-2 float-right d-lg-none"><FontAwesomeIcon icon={detailsOpen ? faChevronUp : faChevronDown} /></i>

                } */}
      </div>
    );
  }

  // console.log(classItem.DAYLIST)
  var printedDay = false;
  daysAndDates.push(
    <div className={'class-results-cell pull-left days ' + hideClass}>
      {classItem.DAYLIST.map((day, i) => {
        // console.log("-->"+day+"<----")
        //day = day.replace("&nbsp;", <div  dangerouslySetInnerHTML={{__html: day}}></div>)
        if (day === undefined || day === '&nbsp;' || day.trim() === '')
          return (
            <p className="mb-0 text-nowrap  d-none d-lg-block" key={i}>
              &nbsp;
            </p>
          );
        else {
          printedDay = true;
          return (
            <p className="mb-0 text-nowrap  " key={i}>
              {day}
            </p>
          );
        }
      })}
    </div>
  );

  if (printedDay && classItem.CLAS.STARTTIMES.length === 1)
    daysAndDates.push(
      <div className={'ps-1 pe-1 d-lg-none pull-left ' + hideClass}>|</div>
    );

  var printedStartTime = false;
  daysAndDates.push(
    <div className={'class-results-cell pull-left start ' + hideClass}>
      {classItem.CLAS.STARTTIMES.map((time, i) => {
        if (time === null)
          return (
            <p className="mb-0 text-nowrap d-none d-lg-block" key={i}>
              &nbsp;
            </p>
          );
        else {
          printedStartTime = true;
          return (
            <p className="mb-0 text-nowrap" key={i}>
              {time}
            </p>
          );
        }
      })}
    </div>
  );

  if (printedStartTime && classItem.CLAS.ENDTIMES.length === 1)
    daysAndDates.push(<div className="ps-1 pe-1 d-lg-none pull-left">-</div>);

  daysAndDates.push(
    <div className={'class-results-cell end ' + hideClass}>
      {classItem.CLAS.ENDTIMES.map((time, i) => {
        if (time === null)
          return (
            <p className="mb-0 text-nowrap d-none d-lg-block" key={i}>
              &nbsp;
            </p>
          );
        else
          return (
            <p className="mb-0 text-nowrap" key={i}>
              {time}
            </p>
          );
      })}
    </div>
  );

  return daysAndDates;
}

function getLocation(classItem, callback) {
  var location = [];

  var hideClass = '';
  if (
    classItem.LOCATIONBUILDING !== null &&
    classItem.LOCATIONBUILDING.length > 2
  )
    //if there are multiple locations, we have to hide them on small and medium devices
    hideClass = 'd-none d-lg-block';

  location.push(
    <div className={'class-results-cell text-nowrap location ' + hideClass}>
      {classItem.LOCATIONBUILDING !== null &&
        classItem.LOCATIONBUILDING.length > 0 && (
          <>
            {classItem.LOCATIONBUILDING.map((location, index) => {
              if (
                location.NAME &&
                (location.NAME === 'ASU Online' || location.NAME === 'iCourse')
              ) {
                return (
                  <div
                    onClick={callback}
                    className="bold-hyperlink pointer  title d-none d-lg-inline border-focus mb-0"
                    key={index}
                    tabIndex={0}
                    title={location.ADDRESS}
                  >
                    {location.NAME}
                  </div>
                );
              } else if (location.URL === '') {
                if (location.NAME && location.NAME === 'ASU Sync')
                  return (
                    <p className="mb-0" key={index}>
                      <a
                        href="https://provost.asu.edu/sync/students"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {location.NAME}
                      </a>
                    </p>
                  );
                else
                  return (
                    <p className="mb-0" key={index}>
                      {location.NAME}
                    </p>
                  );
              } else {
                return (
                  <p className="mb-0" key={index}>
                    <a
                      href={location.URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {location.NAME}
                    </a>
                  </p>
                );
              }
            })}
          </>
        )}
    </div>
  );
  return location;
}

function getUnits(classItem) {
  var units = [];
  // if(classItem.CLAS.CLASSTYPE && classItem.CLAS.CLASSTYPE === 'N'){
  //     units.push("0");
  // }else{
  units.push(classItem.CLAS.UNITSMINIMUM);
  if (classItem.CLAS.UNITSMAXIMUM !== classItem.CLAS.UNITSMINIMUM) {
    units.push(<span>&nbsp;-&nbsp;{classItem.CLAS.UNITSMAXIMUM}</span>);
  }
  // }
  return units;
}
