import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import UtilFunctions from "./UtilFunctions";
import serviceauth from "./auth/serviceauth";
import isEmpty from "lodash.isempty";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Autosuggestions from "./components/Autosuggestions";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import StaticData from './StaticData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ClassBanner from "./components/ClassBanner";
// import Button from "react-bootstrap/Button";

const globalFields = {
    advanced: true,
    navTree: [],
    showBanner1: "N",
    banner1: null,
    showBanner2: "N",
    banner2: null,
    turnOffApp: false,
    termList: [],
    fullTermList: [],
    term: "",
}
const formFields = {

    subject: "",
    catalogNbr: "",
    keywords: "",
    campus: new Set(),
    session: new Set(),
    level: new Set(),
    gs: new Set(),
    gsgold: "",
    gsmaroon: new Set(),
    college: new Set(),
    units: new Set(),
    daysOfWeek: new Set(),
    honors: false,
    promod: false,
    instructor: "",
    // campusOrOnlineSelection:"A",
    searchType: "all",
    startTime: "",
    endTime: "",
    startDate: "",
    endDate: "",
    classNbr: "",
}

function getDropdownLabel(data, filter, placeholder) {
    var label = ""
    var count = -1
    data.forEach(option => {
        if (filter.has(option.value)) {
            if (count < 0)
                label = option.label + " "

            count++
        }

    });

    if (label === "")
        return <div className='btn-placeholder' dangerouslySetInnerHTML={{ __html: placeholder }}></div>
    else {
        if (count > 0)
            return <><div className='label-wrap'>{label}</div>+{count}</>
        else
            return <div className='label-wrap'>{label}</div>
    }

    // return label !== "" ? label : "Select "+ placeholder
}

function getDropdown(label, name, data, filter, onChange, ariaLabel) {

    // const data = StaticData.locationList



    return <Dropdown className="filter-dropdown">
        {/* <Dropdown.Toggle id="dropdown-basic" className="advanced-search-dropdown-button asu form-control" active={false}> */}
        <Dropdown.Toggle active={false} bsPrefix="custom-dropdown-toggle" variant="advanced-search" aria-label={ariaLabel} id={`${name}-dropdown`}>
            {getDropdownLabel(data, filter, label)}
            <FontAwesomeIcon icon={faChevronDown} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {

                data.map((item, index) => {
                    return <React.Fragment key={item.label}>
                        <Form.Check
                            type="checkbox"
                            label={item.label}
                            checked={filter.has(item.value)}
                            value={item.value}
                            onChange={onChange}
                            name={name}
                            mylabel={item.label}
                            className="filter-check "
                            tabIndex={0}
                            id={item.value}
                        />
                        {/* <div>
                        <label className='form-check-label-bootstrap-asu text-break'>
                            <input
                                type="checkbox"
                                label={item.label}
                                checked={filter.has(item.value)}
                                value={item.value}
                                onChange={onChange}
                                name={name}
                                mylabel={item.label}
                                className="filter-check bootstrap-asu "
                                tabIndex={0}
                            />
                            <span className='pl-2'>{item.label}</span>
                            </label>
                        </div> */}

                    </React.Fragment>
                })

            }
        </Dropdown.Menu>

    </Dropdown>
}


class AdvancedSearch extends Component {
    constructor(props) {
        super(props);


        this.state = Object.assign(globalFields, formFields);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        // this.handleClearAllFilters = this.handleClearAllFilters.bind(this)
        this.handleDateFocus = this.handleDateFocus.bind(this)
    }

    async componentDidMount() {




        const appSettings = await UtilFunctions.getAppSettings()
        if ((appSettings != null && appSettings.get("system_enabled") != null && appSettings.get("system_enabled") === "N") ||
            appSettings == null) {//turn off app
            this.setState({
                turnOffApp: true
            })
        } else {
            fetch(serviceauth.getRequestObject(UtilFunctions.getTermListUrl(), "GET"))
                .then((response) => {
                    if (response.status === 401) {
                        serviceauth.reauth();
                    }
                    return response.json();
                })
                .then(async (termList) => {
                    const data = UtilFunctions.parseUrl(this.props.location.search);
                    // console.log(this.props.location.search)
                    const term = isEmpty(data.term)
                        ? [termList.currentTerm[0].value]
                        : [data.term];

                    var showArchiveBool = UtilFunctions.showArchive(term[0], termList.fullList)
                    var pastTermList = UtilFunctions.getPastTermList(termList.fullList)
                    var currentTermList = UtilFunctions.getCurrentTermList(termList.fullList)
                    var asuOnlineSet = new Set();//force preselecting asuonline for online students
                    asuOnlineSet.add("ASUONLINE");
                    //Split gold-maroon gs
                    // let gsObj = {
                    //     gold: new Set(),
                    //     maroon: new Set()
                    // }
                    // if(!isEmpty(data.gen_studies)){
                    //    let propGs = data.gen_studies.split(",");
                    //    propGs.forEach(element => {
                    //         if(element.length === 7)
                    //             gsObj.gold.add(element);
                    //         else
                    //             gsObj.maroon.add(element);
                    //    });
                    // }

                    //split gs from url
                    let gsDesignations = UtilFunctions.getGSDesignationsFromURL(data.gen_studies);
                    // console.log(gsDesignations)
                    this.setState({
                        currentTerm: termList.currentTerm[0].value,
                        term: term,
                        termList: showArchiveBool ? pastTermList : currentTermList,
                        navTree: await UtilFunctions.getHeaderNavTree(term),
                        subject: isEmpty(data.subject) ? "" : data.subject,
                        catalogNbr: isEmpty(data.catalogNbr) ? "" : data.catalogNbr,
                        keywords: isEmpty(data.keywords) ? "" : data.keywords,
                        campus: isCurrentStudentOnline() ? asuOnlineSet : (isEmpty(data.campus) ? new Set() : new Set(data.campus.split(","))),
                        session: isEmpty(data.session) ? new Set() : new Set(data.session.split(",")),
                        level: isEmpty(data.level) ? new Set() : new Set(data.level.split(",")),
                        gs: isEmpty(data.gen_studies) ? new Set() : new Set(data.gen_studies.split(",")),
                        gsgold: gsDesignations.gold,
                        gsmaroon: gsDesignations.maroon.length === 0 ? new Set() : new Set(gsDesignations.maroon),
                        college: isEmpty(data.college) ? new Set() : new Set(data.college.split(",")),
                        units: isEmpty(data.units) ? new Set() : new Set(data.units.split(",")),
                        daysOfWeek: isEmpty(data.daysOfWeek) ? new Set() : new Set(data.daysOfWeek.split(",")),
                        honors: isEmpty(data.honors) ? false : data.honors === "T",
                        promod: isEmpty(data.promod) ? false : data.promod === "T",
                        instructor: isEmpty(data.instructorName) ? "" : data.instructorName,
                        //campusOrOnlineSelection: isEmpty(data.instructorName) ? "" : data.instructorName,
                        searchType: isEmpty(data.searchType) ? "all" : data.searchType,
                        classNbr: isEmpty(data.classNbr) ? "" : data.classNbr,
                        startTime: isEmpty(data.startTime) ? "" : data.startTime,
                        endTime: isEmpty(data.endTime) ? "" : data.endTime,
                        startDate: isEmpty(data.startDate) ? "" : UtilFunctions.convertDateToDisplay(data.startDate),
                        endDate: isEmpty(data.endDate) ? "" : UtilFunctions.convertDateToDisplay(data.endDate),
                        showBanner2: appSettings.get("banner_status_2"),
                        banner2: appSettings.get("banner_text_2"),
                    });
                }).catch(err => { console.log(err) });
        }
    }

    handleChange(event) {
        // console.log(event.target.name + " " + event.target.value + " " + event.target.checked + " " +event.target.type)

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked
            })
        } else {
            var value = event.target.value
            if (event.target.name === "subject")
                value = value.toUpperCase()

            this.setState({
                [event.target.name]: value
            })


        }

    }



    handleCheck(event) {
        // console.log(event.target.name + " " + event.target.value + " " + event.target.checked)
        const chkName = event.target.name
        const chkValue = event.target.value
        var selectedList = this.state[chkName]
        //if checkbox is checked
        if (event.target.checked) {
            selectedList.add(chkValue)
        } else {//if checkbox is NOT checked
            selectedList.delete(chkValue)
        }
        this.setState({
            [chkName]: selectedList
        })

    }

    handleKeyDown(event) {

        if (event.keyCode === 13) {

            this.props.history.push(`/catalog/classes/classlist?${UtilFunctions.getQueryStringFromState(this.state)}`)

        }
    }

    handleSearch(e) {

        // console.log(`/catalog/classes/classlist?${UtilFunctions.getQueryStringFromState(this.state)}`)
        // update state with the combination of the two gs designation
        let gsMaroonFromState = this.state.gsmaroon;
        if(this.state.gsgold !== "")
            gsMaroonFromState.add(this.state.gsgold);

        this.setState({
            gs:gsMaroonFromState,
        },()=>{
            this.props.history.push(`/catalog/classes/classlist?${UtilFunctions.getQueryStringFromState(this.state)}`)
        });

    }



    // handleClearAllFilters(e){
    //     // console.log(this.state)
    //     // var test = Object.assign(formFields,{term:this.state.currentTerm})
    //     // console.log(test)
    //     // this.setState(Object.assign(formFields,{term:this.state.currentTerm}))
    //     // this.props.history.push("/catalog/advanced")
    //     window.location.href = "/catalog/advanced";
    // }

    handleDateFocus(e) {//fix for ios safari not clearing date fields
        e.currentTarget.defaultValue = '';
    }

    render() {
        // const campusOrOnlineSelection = UtilFunctions.getCampusOrOnlineSelection()
        return (
            <React.Fragment>

                {
                    this.state.turnOffApp &&
                    <Container>
                        App is Down
                    </Container>
                }

                {
                    !this.state.turnOffApp &&
                    <>
                        {/* <a id="skip-to-content" className="anchor" href="https://catalog.apps.asu.edu/catalog/classes">a</a> */}
                        <Container fluid={true} className="p-0 " id="main">
                            <Header navTree={this.state.navTree} />
                            {
                                this.state.showBanner2 === "Y" &&
                                <ClassBanner bannerColor="gray" bannerHtml={this.state.banner2}></ClassBanner>
                            }
                            <Container className=" mb-5">
                                <h2 className=" pt-4">
                                    <span className="highlight-black">
                                        Class Search
                                    </span>
                                </h2>
                                <div className='row'>
                                    <div className='col-12 col-md-6'><h3 className='mt-5'>Advanced Search</h3></div>
                                    <div className='col-12 col-md-6  mt-md-5 '><a className='float-md-end' href="/catalog/advanced" tabIndex={0} >Clear all filters</a></div>
                                </div>

                                <form className='admin uds-form' id="skip-to-content">
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-lg-2 mt-2 mt-lg-3">
                                                <label htmlFor="term">Term</label>
                                                <select className="form-control asu form-select"
                                                    id="term"
                                                    value={this.state.term}
                                                    onChange={this.handleChange}
                                                    name="term"
                                                    tabIndex={0}
                                                    aria-label="Select Term"
                                                >
                                                    <option value="" disabled selected>Select Term</option>
                                                    {this.state.termList != null &&
                                                        this.state.termList.map((term) => {
                                                            return (
                                                                <option value={term.value} key={term.value}>
                                                                    {term.label}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                    {/* {
                                                !this.state.showArchive &&
                                                <option value={this.state.firstPastTerm} >
                                                    Previous Terms
                                                </option>
                                            } */}
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="subject">Subject</label>
                                                <Autosuggestions
                                                    term={this.state.term}
                                                    name="subject"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    value={this.state.subject}
                                                    className="asu form-control"
                                                    id="subject"
                                                    placeholder="Enter Subject"
                                                />
                                            </div>
                                            <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="catalogNbr">Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control asu"
                                                    placeholder="Enter Number"
                                                    inputMode="numeric"
                                                    pattern="\d*"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    name="catalogNbr"
                                                    value={this.state.catalogNbr}
                                                    id="catalogNbr"
                                                />
                                            </div>
                                            <div className="col-lg-3 mt-2 mt-lg-3 col-12">
                                                <label htmlFor="keyword">Keyword search</label>
                                                <input type="text"
                                                    placeholder="Search by keyword"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    name="keywords"
                                                    className="asu form-control"
                                                    value={this.state.keywords}
                                                    id="keyword"
                                                />
                                            </div>

                                            <div className="col-lg-3 mt-2 mt-lg-3 col-12">
                                                <label htmlFor="instructor">Instructor</label>
                                                <input type="text"
                                                    placeholder="Enter Instructor"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    name="instructor"
                                                    className="asu form-control"
                                                    value={this.state.instructor}
                                                    id="instructor" />
                                            </div>
                                        </div>
                                        <div className="row ">

                                            <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="classNbr">Class Number</label>
                                                <input
                                                    type="text"
                                                    inputMode="numeric"
                                                    pattern="\d*"
                                                    placeholder="Enter Class Number"
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    name="classNbr"
                                                    className="asu form-control"
                                                    value={this.state.classNbr}
                                                    id="classNbr"
                                                />
                                            </div>
                                            <div className="col-lg-2  col-6 mt-2 mt-lg-3">
                                                <label htmlFor="level-dropdown">Level</label>
                                                {getDropdown("Select Level", "level", StaticData.levelList, this.state.level, this.handleCheck,"select level")}
                                            </div>

                                            <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="units-dropdown">Number of Units</label>
                                                {getDropdown("Select Units", "units", StaticData.unitsList, this.state.units, this.handleCheck, "select units")}
                                            </div>

                                            {/* <div className="col-lg-3 mt-2 mt-lg-3 col-12">
                                                <label>General Studies</label>
                                                {getDropdown("Select General Studies", "gs", StaticData.gsList, this.state.gs, this.handleCheck, "select general studies")}
                                            </div> */}
                                            <div className="col-lg-3 mt-2 mt-lg-3 col-12">
                                                <label htmlFor="college-dropdown">College/School</label>
                                                {getDropdown("Select College or School", "college", StaticData.collegeList, this.state.college, this.handleCheck,"Select College or School")}
                                            </div>

                                            <div className="col-lg-3 mt-2 mt-lg-3 col-12">
                                                <label htmlFor="campus-dropdown">Location</label>
                                                {//online students
                                                    isCurrentStudentOnline() &&
                                                    getDropdown("Select location(s)", "campus", StaticData.onlineLocationList, this.state.campus, this.handleCheck,"Select location(s)")

                                                }
                                                {//everyone else
                                                    !isCurrentStudentOnline() &&
                                                    getDropdown("Select location(s)", "campus", StaticData.locationList, this.state.campus, this.handleCheck,"Select location(s)")

                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="session-dropdown">Session</label>
                                                {getDropdown("Select Session", "session", StaticData.sessionsList, this.state.session, this.handleCheck,"Select session")}
                                            </div>

                                            {/* <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                        <label>Start Time</label>
                                        <input type="time"
                                            className="form-control asu"
                                            placeholder="Start Time"
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            name="startTime"
                                            value={this.state.startTime}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-6 mt-2 mt-lg-3">
                                        <label>End Time</label>
                                        <input type="time"
                                            className="form-control asu"
                                            placeholder="End Time"
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                            name="endTime"
                                            value={this.state.endTime} />
                                    </div> */}
                                            <div className="col-lg-4 mt-2 mt-lg-3 col-12">
                                                <label htmlFor="daysOfWeek-dropdown">Days of the Week</label>
                                                {getDropdown("Select Day(s)", "daysOfWeek", StaticData.daysOfWeekList, this.state.daysOfWeek, this.handleCheck,"Select Days of the week")}
                                            </div>
                                            <div className="col-lg-3 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="startDate">Start Date</label>
                                                <input type="date"
                                                    className="form-control asu"
                                                    placeholder='Start Date'
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    name="startDate"
                                                    value={this.state.startDate}
                                                    onFocus={this.handleDateFocus}
                                                    aria-label="Start Date"
                                                    id="startDate"/>
                                            </div>
                                            <div className="col-lg-3 col-6 mt-2 mt-lg-3">
                                                <label htmlFor="endDate">End Date</label>
                                                <input type="date"
                                                    className="form-control asu"
                                                    placeholder='End Date'
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    name="endDate"
                                                    value={this.state.endDate}
                                                    onFocus={this.handleDateFocus}
                                                    id="endDate" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4  col-12 mt-2 mt-lg-3">
                                                <div className='gs-label gs-label-gold gs-header'>General Studies Gold</div>
                                                <p className=' mt-1 font-14'>{StaticData.GS_GOLD_TEXT}</p>
                                                {/* {getDropdown("Select General Studies Gold", "gs", StaticData.gsListGold, this.state.gs, this.handleCheck, "select general studies")} */}
                                                <select className="form-control asu form-select"
                                                    id="gsgold"
                                                    value={this.state.gsgold}
                                                    onChange={this.handleChange}
                                                    name="gsgold"
                                                    tabIndex={0}
                                                    aria-label="Select General Studies Gold">
                                                        <option value={""} key={"empty"} className=''>
                                                            Select General Studies Gold
                                                        </option>
                                                    {
                                                        StaticData.gsListGold.map((myGs) => {
                                                            return (
                                                                <option value={myGs.value} key={myGs.value}>
                                                                    {myGs.label}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-lg-4  col-12 mt-2 mt-lg-3">
                                                <div className='gs-label gs-label-maroon gs-header'>General Studies Maroon</div>
                                                <p className=' mt-1 font-14'>{StaticData.GS_MAROON_TEXT}</p>
                                                {getDropdown("Select General Studies Maroon", "gsmaroon", StaticData.gsListMaroon, this.state.gsmaroon, this.handleCheck, "select general studies")}
                                            </div>
                                            <div className="col-lg-1  col-12 mt-2 mt-lg-3 d-none d-lg-block"></div>
                                            <div className="col-lg-2  col-6 mt-2 mt-lg-3">
                                                <label>Class Status</label>
                                                <div className="form-check mt-0">
                                                    <input className="form-check-input bootstrap-asu" type="radio" name="searchType" value="open" id="search-open"
                                                        onChange={this.handleChange}
                                                        checked={this.state.searchType === "open"} />
                                                    <label htmlFor="search-open" className='form-check-label-bootstrap-asu'>Open Seats</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input bootstrap-asu" type="radio" name="searchType" value="all" id='search-all'
                                                        onChange={this.handleChange}
                                                        checked={this.state.searchType === "all"} />
                                                    <label htmlFor="search-all" className='form-check-label-bootstrap-asu'>All Classes</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-1  col-6 mt-2 mt-lg-3">
                                                <label htmlFor="honors-check">Honors</label>
                                                <div><input
                                                    type="checkbox"
                                                    className="bootstrap-asu"
                                                    name="honors"
                                                    value="T"
                                                    checked={this.state.honors}
                                                    onChange={this.handleChange}
                                                    style={{width: "20px", height: "20px"}}
                                                    id="honors-check"
                                                /></div>
                                            </div>
                                            {/* <div className="col-lg-2  col-6 mt-2 mt-lg-3">
                                        <label>Project-based</label>
                                        <div><input
                                            type="checkbox"
                                            className="bootstrap-asu"
                                            name="promod"
                                            value="T"
                                            checked={this.state.promod}
                                            onChange={this.handleChange}
                                        /></div>
                                    </div> */}
                                            {/* {
                                        campusOrOnlineSelection === "A" &&
                                        <div className="col-lg-3  col-md-6 col-12 mt-2 mt-lg-3">
                                            <label>Class Type</label>
                                            <div className="form-check">
                                                <input className="form-check-input bootstrap-asu" type="radio" name="campusOrOnlineSelection" value="C" id="on-campus"
                                                onChange={this.handleChange}
                                                checked={this.state.campusOrOnlineSelection === "C"}/>
                                                <label htmlFor="on-campus" className='form-check-label-bootstrap-asu'>In-person & iCourses</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input bootstrap-asu" type="radio" name="campusOrOnlineSelection" value="O"  id="online"
                                                onChange={this.handleChange}
                                                checked={this.state.campusOrOnlineSelection === "O"}/>
                                                <label htmlFor="online" className='form-check-label-bootstrap-asu'>ASU Online Classes</label>
                                            </div>
                                        </div>
                                    } */}

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-2 mt-2 mt-lg-3">
                                                <button id="search-button"
                                                    type="button"
                                                    className="btn-xl btn btn-gold"
                                                    onClick={this.handleSearch}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} className="me-2" />
                                                    Search Classes
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </Container>
                            <Footer />

                        </Container>
                    </>
                }
            </React.Fragment>

        )
    }



}
export default AdvancedSearch

function isCurrentStudentOnline() {
    return sessionStorage.getItem(serviceauth.SS_IS_STUDENT) === "Y" &&
        sessionStorage.getItem(serviceauth.SS_IS_ONLINE) === "Y" && sessionStorage.getItem(serviceauth.SS_IS_STAFF) === "N"
}
