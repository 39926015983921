import React, { Component } from 'react';
import '../css/App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import serviceauth from '../auth/serviceauth';
import UtilFunctions from '../UtilFunctions';
// import isEqual from 'lodash.isequal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import Toast from './Toast';
import SeatAvailability from './SeatAvailability';
import YoutubeEmbed from './YoutubeEmbed';
import { getInstructorList, getInstructorUrl } from './ClassRow';

const entities = require('entities');

var XMLParser = require('react-xml-parser');

class ClassDetails extends Component {
  constructor(props) {
    super();

    this.state = {
      classItem: null,
      course: null,
      books: null,
      hiddenCourseUrl: '',
      toast: false,
      combined: [],
    };
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.hiddenCourseUrl = React.createRef();
  }

  componentDidMount() {
    // if(prevProps.crseId !== this.props.crseId ||!isEqual(this.props.classItem,prevProps.classItem)){
    // if (this.props.detailsOpen && (prevProps.crseId !== this.props.crseId || !isEqual(this.props.classItem, prevProps.classItem))) {
    //  console.log("going to update details")
    var params = {
      course_id: this.props.crseId,
      term: this.props.classItem.CLAS.STRM,
      subject: this.props.classItem.CLAS.SUBJECT,
      catalogNbr: this.props.classItem.CLAS.CATALOGNBR,
    };
    // console.log(params)
    // console.log("Loading course info in  ClassDetails compDidUpdate for course id " + UtilFunctions.getCourseSearchUrl(params))

    fetch(
      serviceauth.getRequestObject(
        UtilFunctions.getCourseSearchUrl(params),
        'GET'
      )
    )
      .then((response) => {
        if (response.status === 401) {
          serviceauth.reauth();
        }
        return response.json();
      })
      .then((course) => {
        this.setState({
          course: course[0],
        });
      });

    //fetch books
    var url =
      UtilFunctions.getBookstoreURL() +
      encodeURI(UtilFunctions.getBookstoreURLHOST()) +
      '&method=bookstore.getBooks&slns=' +
      this.props.classItem.CLAS.CLASSNBR +
      '&strms=' +
      this.props.classItem.CLAS.STRM;
    console.log(url);
    try {
      fetch(url)
        .then((response) => response.text())
        .then((book) => {
          var xml = new XMLParser().parseFromString(book); // Assume xmlText contains the example XML
          // console.log(xml);
          //console.log(xml.getElementsByTagName('term'));
          this.setState({
            books: xml,
          });
        });
    } catch (error) {
      console.error(error);
    }

    // }
    // else
    // console.log("not going to update details")

    //check for combined classes
    if (
      this.props.classItem.CLAS.SCTNCOMBINEDID &&
      this.props.classItem.CLAS.SCTNCOMBINEDID !== ''
    ) {
      params = {
        sctncombinedid: this.props.classItem.CLAS.SCTNCOMBINEDID,
        term: this.props.classItem.CLAS.STRM,
        session: this.props.classItem.CLAS.SESSIONCODE,
        // classNbr: this.props.classItem.CLAS.CLASSNBR,
      };
      fetch(
        serviceauth.getRequestObject(
          UtilFunctions.getClassSearchUrl(params),
          'GET'
        )
      )
        .then((response) => {
          if (response.status === 401) {
            serviceauth.reauth();
          }
          return response.json();
        })
        .then((combined) => {
          this.setState({
            combined: combined,
          });
        });
    }
  }

  handleCopyLink(e, classNbr) {
    e.preventDefault();
    const url =
      window.location.href.split('?')[0] +
      '?keywords=' +
      classNbr +
      '&searchType=all&term=' +
      this.props.classItem.CLAS.STRM +
      '#detailsOpen=' +
      this.props.classItem.CLAS.CLASSNBR +
      '-' +
      this.props.classItem.CLAS.CRSEID;
    this.setState(
      {
        hiddenCourseUrl: url,
        toast: true,
      },
      () => {
        this.hiddenCourseUrl.current.select();
        document.execCommand('copy'); //copy to clipboard
        this.hiddenCourseUrl.current.blur();
      }
    );
  }

  render() {
    const courseItem = this.state.course;
    const classItem = this.props.classItem;
    var books = null;
    if (this.state.books != null) {
      books = this.state.books.getElementsByTagName('bookshelf');
    }

    return (
      <Container className="class-details" fluid={true}>
        {courseItem && (
          <Row>
            <Col
              sm="12"
              md="6"
              className="border-sm-right mt-4 mb-md-4 mb-xs-0"
              id="class-details"
            >
              <h5 className="font-weight-bold">Course Description</h5>
              <p>{courseItem.DESCRLONG}</p>

              <h5 className="font-weight-bold">Enrollment Requirements</h5>
              <p>{getEnrollmentRequirements(courseItem, classItem)}</p>

              {/* <h5 className="font-weight-bold">Reserved Seat Information</h5> */}

              <h5>Consent</h5>
              {classItem.CLAS.CONSENT === 'D' && (
                <p>Department consent is required.</p>
              )}
              {classItem.CLAS.CONSENT === 'I' && (
                <p>Instructor consent is required.</p>
              )}
              {classItem.CLAS.CONSENT === 'N' && (
                <p>No consent required for this class.</p>
              )}

              <h5 className="font-weight-bold">Course Notes</h5>
              {getClassNote(classItem)}

              <h5 className="font-weight-bold">Fees</h5>
              {getClassFees(classItem)}

              <h5 className="font-weight-bold mt-4">Instructor(s)</h5>
              {getInstructor(classItem)}

              <h5 className="font-weight-bold mt-4">Number</h5>
              <p>{classItem.CLAS.CLASSNBR}</p>

              <h5 className="font-weight-bold mt-4">Offered By</h5>
              <p>
                {classItem.OFFEREDBY.INFO && (
                  <a href={classItem.OFFEREDBY.INFO.ASUCOLLEGEURL}>
                    {classItem.OFFEREDBY.INFO.DESCRFORMAL}
                  </a>
                )}
              </p>

              {classItem.CLAS.DESCR4 !== '' && (
                <>
                  {/* <h5 className="font-weight-bold">General Studies</h5> */}
                  <p className="pt-1">
                    {UtilFunctions.getGSText(
                      classItem.GSMAROON,
                      classItem.GSGOLD
                    )}
                  </p>
                </>
              )}

              <Row>
                <Col sm={4}>
                  <h5 className="font-weight-bold">Units</h5>
                  <p>
                    {classItem.CLAS.UNITSMINIMUM}
                    {classItem.CLAS.UNITSMAXIMUM !==
                      classItem.CLAS.UNITSMINIMUM && (
                      <span> - {classItem.CLAS.UNITSMAXIMUM}</span>
                    )}
                  </p>
                </Col>
                <Col sm={4}>
                  <h5 className="font-weight-bold">Repeatable for credit</h5>
                  <p>{getPrettyRepeatable(classItem)}</p>
                </Col>

                <Col sm={4}>
                  <h5 className="font-weight-bold">Component</h5>
                  <p>{classItem.CLAS.SSR}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <h5 className="font-weight-bold">Last day to enroll</h5>
                  <p>{getFormattedDate(classItem.CLAS.ENROLLDEADLINE)}</p>
                </Col>
                <Col sm={4}>
                  <h5 className="font-weight-bold">Drop deadline</h5>
                  <p>{getFormattedDate(classItem.CLAS.DROPRETAINRECORD)}</p>
                </Col>

                <Col sm={4}>
                  <h5 className="font-weight-bold">
                    Course withdrawal deadline
                  </h5>
                  <p>{getFormattedDate(classItem.CLAS.DROPWITHPENALTY)}</p>
                </Col>
              </Row>

              {this.state.combined &&
                this.state.combined.classes &&
                this.state.combined.classes.length > 0 && (
                  <>
                    <h5 className="font-weight-bold">Combined with</h5>
                    <div className=" pb-1 columns-combined">
                      {this.state.combined.classes.map((comb, index) => {
                        var content = [];
                        if (
                          comb.CLAS.CLASSNBR !==
                          this.props.classItem.CLAS.CLASSNBR
                        ) {
                          content.push(
                            <>
                              <a
                                href={`/catalog/classes/classlist?${UtilFunctions.getQueryStringFromState(
                                  {
                                    keywords: comb.CLAS.CLASSNBR,
                                    term: this.props.classItem.CLAS.STRM,
                                    searchType: 'all',
                                  }
                                )}#detailsOpen=${comb.CLAS.CLASSNBR}`}
                              >
                                {comb.CLAS.SUBJECT}&nbsp;{comb.CLAS.CATALOGNBR}
                                &nbsp;({comb.CLAS.CLASSNBR})
                              </a>{' '}
                            </>
                          );
                        }

                        return content;
                      })}
                    </div>
                  </>
                )}

              <Row className="pt-4 pb-1">
                <Col>
                  {/* <a href="copy"
                                        title="Copy Class to Clipboard"
                                        onClick={(e) => this.handleCopyLink(e, classItem.CLAS.CLASSNBR)} variant="link">
                                        <FontAwesomeIcon icon={faLink} className="" /> Copy Class Link
                                    </a> */}
                  <button
                    className="button-link underline mt-2 "
                    onClick={(e) =>
                      this.handleCopyLink(e, classItem.CLAS.CLASSNBR)
                    }
                  >
                    <FontAwesomeIcon icon={faLink} className="" />
                    <span className="ms-1">Copy Class Link</span>
                  </button>
                  <Toast show={this.state.toast} text="Copied to Clipboard!" />
                  <textarea
                    value={this.state.hiddenCourseUrl}
                    className="hidden-course-url"
                    onChange={(e) => this.handleCopyToClipboard()}
                    ref={this.hiddenCourseUrl}
                    tabIndex={-1}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              sm="12"
              md="6"
              id="book-info"
              className="ps-md-4 ps-xs-0 mt-xs-0-2 mt-md-2"
            >
              <h5 className="font-weight-bold mb-0 mt-4 mb-2">Location</h5>
              {classItem.LOCATIONBUILDING !== null &&
                classItem.LOCATIONBUILDING.length > 0 && (
                  <>
                    {classItem.LOCATIONBUILDING.map((location, index) => {
                      var l = [];

                      if (location.NAME === 'ASU Sync') {
                        l.push(
                          <p className="mb-0">
                            <a
                              href="https://provost.asu.edu/sync/students"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {location.NAME}
                            </a>
                          </p>
                        );
                        l.push(
                          <p className="mb-0">
                            ASU Sync enables students to attend live classes
                            remotely via Zoom. Classes are designed to foster
                            active collaboration and discussion in real-time
                            with faculty and peers.
                          </p>
                        );
                      } else if (location.URL === '') {
                        l.push(
                          <p className="mb-0">
                            {location.NAME}
                            <br />
                            {location.ADDRESS}
                          </p>
                        );
                      } else {
                        l.push(
                          <p className="mb-0">
                            <a
                              href={location.URL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {location.NAME}
                            </a>
                            <br />
                            {location.ADDRESS}
                          </p>
                        );
                      }

                      var dayTimesAdded = false;
                      const day = classItem.DAYLIST[index];
                      if (day && day !== '&nbsp;' && day.trim() !== '') {
                        l.push(
                          <div className=" text-nowrap pull-left ">
                            {day}
                            <span className="ml-1 mr-1">|</span>
                          </div>
                        );
                        dayTimesAdded = true;
                      }

                      if (classItem.CLAS.STARTTIMES) {
                        const startTime = classItem.CLAS.STARTTIMES[index];
                        if (
                          startTime &&
                          startTime !== null &&
                          startTime.trim() !== ''
                        ) {
                          l.push(
                            <div className=" text-nowrap pull-left ">
                              {startTime}
                              <span className="ml-1 mr-1">-</span>
                            </div>
                          );
                          dayTimesAdded = true;
                        }
                      }

                      if (classItem.CLAS.ENDTIMES) {
                        const endTime = classItem.CLAS.ENDTIMES[index];
                        if (endTime !== null) {
                          l.push(
                            <>
                              <div className=" text-nowrap pull-left ">
                                {endTime}
                              </div>
                            </>
                          );
                          dayTimesAdded = true;
                        }
                      }

                      if (classItem.CLAS.MEETINGDATES) {
                        const date = classItem.CLAS.MEETINGDATES[index];
                        if (date) {
                          let d1 = date[0];
                          let d2 = date[1];
                          let sessionCode =
                            classItem.CLAS.SESSIONCODE !== 'DYN'
                              ? ' (' + classItem.CLAS.SESSIONCODE + ')'
                              : '';
                          if (dayTimesAdded) l.push(<br />);
                          l.push(
                            <>
                              <div className=" text-nowrap ">
                                {d1 + ' - ' + d2 + sessionCode}
                              </div>
                            </>
                          );
                        }
                      }
                      // console.log(index)
                      if (index < classItem.LOCATIONBUILDING.length - 1) {
                        // console.log("here")
                        l.push(<br />);
                      }

                      return l;
                    })}
                  </>
                )}
              <SeatAvailability
                classItem={classItem}
                seatInfo={classItem.seatInfo}
                cohortCountInfo={classItem.cohortCountInfo}
                reservedSeatsInfo={classItem.reservedSeatsInfo}
                detailsPage={true}
              />

              {books !== null && books.length > 0 && (
                <div className="card mt-md-5 mb-2 book-info mb-3 h-auto">
                  <div className="card-header font-weight-bold">
                    Course Material
                  </div>
                  <div className="card-body ">{bookshelf(books)}</div>
                </div>
              )}
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default ClassDetails;

function getEnrollmentRequirements(courseItem, classItem) {
  var enrReq = '';
  try {
    courseItem.COLLEGEMAP.forEach((college, i) => {
      if (classItem.CLAS.ACADGROUP === college.INFO.ACADGROUP) {
        enrReq = college.INFO.ENROLLREQ;
      }
    });
  } catch (error) {
    // console.log(error)
  }

  return enrReq === '' ? 'None' : enrReq;
}

function showOCourseFee(classItem) {
  try {
    const catalogNbr = classItem.CLAS.CATALOGNBR.trim().split('-')[0];
    if (
      classItem.CLAS.LOCATION !== '' &&
      catalogNbr < '500' &&
      classItem.CLAS.LOCATION.toUpperCase() === 'ASUONLINE' &&
      classItem.CLAS.STRM >= '2127' &&
      classItem.CLAS.STRM < '2227'
    ) {
      var classSubNbr = '';
      if (classItem.CLAS.SUBJECT !== '' && classItem.CLAS.CATALOGNBR !== '')
        classSubNbr = classItem.CLAS.SUBJECT.trim() + classItem.CLAS.CATALOGNBR;
      // console.log(classSubNbr)
      if (
        classSubNbr.indexOf('MAT117') === 0 ||
        classSubNbr.indexOf('MAT142') === 0 ||
        classSubNbr.indexOf('TEL313') === 0 ||
        classSubNbr.indexOf('ASU10') === 0 ||
        classSubNbr.indexOf('ASU11') === 0 ||
        classSubNbr.indexOf('ASU42') === 0 ||
        classSubNbr.indexOf('ASU101') === 0 ||
        classSubNbr.indexOf('ASU19') === 0 ||
        classSubNbr.indexOf('ASU12') === 0 ||
        classItem.CLAS.COMPONENTPRIMARY === 'REC' || //CSCC-235
        classItem.CLAS.COMPONENTPRIMARY === 'LAB'
      ) {
        return false;
      }

      return true;
    }
  } catch (e) {
    console.log(e);
  }

  return false;
}

function getPrettyRepeatable(classItem) {
  const repeatable = classItem.CLAS.CRSEREPEATABLE;
  if (repeatable === 'Y') return 'Yes';
  else if (repeatable === 'N' || repeatable === null) return 'No';
  else if (repeatable === 'V') return 'Varies by topic';

  return repeatable;
}

function getFormattedDate(date) {
  // console.log(date)
  if (date === undefined) return '';
  try {
    var d = new Date(date.replace(/ /g, 'T'));

    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${mo} ${da}, ${ye}`;
  } catch (error) {
    console.log(error);
    return '';
  }
}

function formatAmmount(nbr) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(nbr);
}

function getBook(b) {
  return (
    <Row className="mt-4">
      <Col>
        <p>
          <span className="font-weight-bold">Title:</span>{' '}
          {entities.decodeHTML(b.attributes.title)}
        </p>
        <p>
          <span className="font-weight-bold">Author:</span>{' '}
          {entities.decodeHTML(b.attributes.author)}
        </p>
        <p>
          <span className="font-weight-bold">Edition:</span>{' '}
          {b.attributes.edition}
        </p>
        <p>
          <span className="font-weight-bold">Copyright Year:</span>{' '}
          {b.attributes.copyrightYear}
        </p>
        <p>
          <span className="font-weight-bold">Publisher:</span>{' '}
          {entities.decodeHTML(b.attributes.publisher)}
        </p>
        {b.attributes.format !== undefined && (
          <p>
            <span className="font-weight-bold">Format:</span>{' '}
            {b.attributes.format}
          </p>
        )}

        <p>
          <span className="font-weight-bold">ISBN:</span> {b.attributes.isbn}
        </p>
        {b.attributes.newPrice !== undefined && (
          <p>
            <span className="font-weight-bold">Price New:</span>{' '}
            {formatAmmount(b.attributes.newPrice)}
          </p>
        )}

        {b.attributes.usedPrice !== undefined && (
          <p>
            <span className="font-weight-bold">Price Used:</span>{' '}
            {formatAmmount(b.attributes.usedPrice)}
          </p>
        )}
        {b.attributes.rentalNewPrice !== undefined && (
          <p>
            <span className="font-weight-bold">Rental Price New:</span>{' '}
            {formatAmmount(b.attributes.rentalNewPrice)}
          </p>
        )}
        {b.attributes.rentalUsedPrice !== undefined && (
          <p>
            <span className="font-weight-bold">Rental Price Used:</span>{' '}
            {formatAmmount(b.attributes.rentalUsedPrice)}
          </p>
        )}
        {b.attributes.digitalRentalPrice !== undefined && (
          <p>
            <span className="font-weight-bold">Digital Rental Price:</span>{' '}
            {formatAmmount(b.attributes.digitalRentalPrice)}
          </p>
        )}
        {b.attributes.digitalPrice !== undefined && (
          <p>
            <span className="font-weight-bold">Digital Price:</span>{' '}
            {formatAmmount(b.attributes.digitalPrice)}
          </p>
        )}
      </Col>
      <Col>
        <img src={b.attributes.imageUrl} alt="img" />
      </Col>
    </Row>
  );
}

// function getMessage1(show){
//     if(show){
//             return <Row>
//             <Col>
//                 <p className="font-weight-bold">Materials for this class are provided at a negotiated rate at professor request,
//                 auto-delivered to you prior to class start. Charge appears in My ASU Finance tab as item
//         type “Bkstr Publisher Negotiate Rate” approx. 1 month after class start.</p>
//             </Col>
//         </Row>
//     }else
//      return <></>
// }

function getClassNote(classItem) {
  var note = [];
  // note.push(<YoutubeEmbed embedId="rokGy0huYEA" />)

  if (
    classItem.NOTES &&
    classItem.NOTES !== null &&
    classItem.NOTES.trim() !== ''
  ) {
    const noteText = linkify(classItem.NOTES);

    note.push(<p dangerouslySetInnerHTML={{ __html: noteText }}></p>);
  }

  if (classItem.CLAS.IFRAMENOTES && classItem.CLAS.IFRAMENOTES !== '') {
    // note.push(<p>here</p>)
    // note.push(<p>{classItem.CLAS.IFRAMENOTES}</p>)
    note.push(<YoutubeEmbed embedId={classItem.CLAS.IFRAMENOTES} />);
  }

  if (
    classItem.CLAS.INSTRUCTOREDIT !== '' &&
    classItem.CLAS.INSTRUCTOREDIT === 'C'
  )
    note.push(<p>Must select single instructor during enrollment.</p>);

  if (note.length === 0) note.push(<p>None</p>);

  // if(note.length === 0 && !showOCourseFee(classItem))
  //     note.push(<p>None</p>)

  // if((classItem.CLAS.FLATAMOUNT1 !== "" || classItem.CLAS.FLATAMOUNT2 !== "")
  //     &&
  //      //CSCC-236 Hide the $250 course fee that is displayed on Graduate FMS classes
  //     !(classItem.CLAS.ACADCAREER === "GRAD" && classItem.CLAS.SUBJECT === "FMS")

  //     ){
  //         note.push(<React.Fragment>
  //             <h5 className="font-weight-bold">Fees</h5>
  //             <p>{classItem.CLAS.FLATAMOUNT1 !== "" &&

  //                 <>${classItem.CLAS.FLATAMOUNT1} course fee</>
  //             }</p>
  //             <p>{classItem.CLAS.FLATAMOUNT2 !== "" &&

  //                 <>${classItem.CLAS.FLATAMOUNT2} course fee</>
  //             }</p>
  //         </React.Fragment>)

  // }else{
  //     note.push(<h5 className="font-weight-bold">Fees</h5>)
  //     if(!showOCourseFee(classItem))
  //         note.push(<p>None</p>)
  // }

  // if(showOCourseFee(classItem))
  //     note.push(<p>oCourse fee: $10</p>)

  return note;
}

function getClassFees(classItem) {
  var note = [];

  if (
    ((classItem.CLAS.FLATAMOUNT1 !== '' &&
      classItem.CLAS.FLATAMOUNT1 !== '0') ||
      (classItem.CLAS.FLATAMOUNT2 !== '' &&
        classItem.CLAS.FLATAMOUNT2 !== '0')) &&
    //CSCC-236 Hide the $250 course fee that is displayed on Graduate FMS classes
    !(classItem.CLAS.ACADCAREER === 'GRAD' && classItem.CLAS.SUBJECT === 'FMS')
  ) {
    note.push(
      <React.Fragment>
        <h5 className="font-weight-bold">Fees</h5>
        <p>
          {classItem.CLAS.FLATAMOUNT1 !== '' && (
            <>${classItem.CLAS.FLATAMOUNT1} course fee</>
          )}
        </p>
        <p>
          {classItem.CLAS.FLATAMOUNT2 !== '' && (
            <>${classItem.CLAS.FLATAMOUNT2} course fee</>
          )}
        </p>
      </React.Fragment>
    );
  } else {
    if (!showOCourseFee(classItem)) note.push(<p>None</p>);
  }

  if (showOCourseFee(classItem)) note.push(<p>oCourse fee: $10</p>);

  return note;
}

function linkify(text) {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
}

function bookshelf(books) {
  //console.log(books)
  var output = [];
  var adoptionMessage = false;
  for (let index = 0; index < books.length; index++) {
    const bookshelf = books[index];
    if (bookshelf.attributes.includED === 'Y') {
      output.push(
        <Row className="pt-2">
          <Col>
            <p className="font-weight-bold">
              This course is participating in ASU's Inclusive Access program.
              Required digital course materials will be provisioned through
              Canvas and the associated cost automatically charged at a
              discounted price to your student account. Charges will be visible
              in your My ASU Finances tab (under the description Digital
              Integrated Course Mtrl) approx. 1 month after classes start.
            </p>
          </Col>
        </Row>
      );
    }

    if (
      bookshelf.attributes.adoptionMessage === '' ||
      bookshelf.attributes.adoptionMessage === 'NoBooksRequired'
    ) {
      adoptionMessage = true;
      output.push(
        <Row className="pt-2">
          <Col>
            <p className="font-italic">
              No ASU Bookstore materials required. Refer to syllabus for
              additional details.
            </p>
          </Col>
        </Row>
      );
    }
  }

  const requiredBooks = books[0].getElementsByTagName('requiredBooks');
  const recommendedBooks = books[0].getElementsByTagName('recommendedBooks');
  const bookChoices = books[0].getElementsByTagName('bookChoices');
  const bundledBooks = books[0].getElementsByTagName('bundledBooks');

  if (
    !adoptionMessage &&
    requiredBooks[0].children.length === 0 &&
    recommendedBooks[0].children.length === 0 &&
    bookChoices[0].children.length === 0 &&
    bundledBooks[0].children.length === 0
  ) {
    output.push(
      <Row className="pt-2">
        <Col>
          <p className="font-italic">
            Not yet provided by instructor. Refer to syllabus for additional
            details.
          </p>
        </Col>
      </Row>
    );
  } else {
    if (requiredBooks[0].children.length > 0) {
      output.push(<p className="font-weight-bold pt-2">Required Items</p>);
      for (let index = 0; index < requiredBooks[0].children.length; index++) {
        output.push(getBook(requiredBooks[0].children[index]));
      }
    }

    if (recommendedBooks[0].children.length > 0) {
      output.push(<p className="font-weight-bold pt-2">Recommended Items</p>);
      for (
        let index = 0;
        index < recommendedBooks[0].children.length;
        index++
      ) {
        output.push(getBook(recommendedBooks[0].children[index]));
      }
    }

    if (bookChoices[0].children.length > 0) {
      for (let index = 0; index < bookChoices[0].children.length; index++) {
        let b = bookChoices[0].children[index];
        var reqText = 'Recommended ';
        if (b.attributes.isRequired) reqText = 'Required ';

        output.push(
          <p className="font-weight-bold pt-2">
            {reqText}Choice: select {b.attributes.selectionQuantity} of{' '}
            {b.attributes.maxQuantity}{' '}
          </p>
        );
        for (let index = 0; index < b.children.length; index++) {
          output.push(getBook(b.children[index]));
        }
      }
    }

    // * if test="bookstore-response/term/bookshelf/bundledBooks/bundle/bundledOffering/book">(12)
    // *      for-each select="/bookstore-response/term/bookshelf/bundledBooks/bundle">(13)
    // *          if test="@isRequired" then "Required" else "Recommended" end if -> "Bundled Offering"
    // *          for-each select="bundledOffering/book">(14)
    // *              book and image
    // *          enf foreaach (14)
    // *       end foreach (13)
    // * end if (12)
  }

  return output;
}

function getInstructor(classItem) {
  // var instructorList = []

  var instructor = [];

  //Show all instructors if "select during enrolment"
  console.log(classItem.CLAS.INSTRUCTORSLASTNAMELIST);
  if (classItem.CLAS.INSTRUCTOREDIT === 'C') {
    instructor = [];
    if (classItem.CLAS.INSTRUCTORSFIRSTNAMESHOWALL) {
      for (
        let index = 0;
        index < classItem.CLAS.INSTRUCTORSFIRSTNAMESHOWALL.length;
        index++
      ) {
        const lastName =
          classItem.CLAS.INSTRUCTORSLASTNAMESHOWALL[index].split('~')[0];
        const firstName = classItem.CLAS.INSTRUCTORSFIRSTNAMESHOWALL[index];
        const asurite =
          classItem.CLAS.INSTRUCTORSLASTNAMESHOWALL[index].split('~')[1];

        var comma = instructor.length > 0 ? ', ' : '';
        // console.log(lastName === "Staff")
        if (
          (lastName === 'Staff' &&
            classItem.CLAS.INSTRUCTORSLASTNAMESHOWALL.length === 1) ||
          lastName === ''
        )
          instructor.push(<>{comma}Staff</>);
        else if (lastName !== 'Staff') {
          instructor.push(
            <>
              {comma}
              <a
                key={index}
                href={getInstructorUrl(asurite)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {firstName} {lastName}
              </a>
            </>
          );
        }
      }
    } else {
      instructor.push(<>Select instructor during enrollment.</>);
    }
  } else {
    instructor = getInstructorList(classItem);
  }

  if (instructor.length > 0) {
    //desktop
    return instructor;
  }

  //if inst list is empty
  if (instructor.length === 0 && classItem.CLAS.INSTRUCTOREDIT !== 'C')
    return <>Staff</>;
}

/**
 *
 *
 *
 *
 * if bookshelf (1)
 *      foreach bookshelf (2)
 *          if @includED != '' (3)
 *              <div class="title">Materials for this class are provided at a negotiated rate at professor request, auto-delivered to you prior to class start. Charge appears in My ASU Finance tab as item type “Bkstr Publisher Negotiate Rate” approx. 1 month after class start. </div>
 *          end if  (3)
 *
 *          if @adoptionMessage = '' or @adoptionMessage = 'NoBooksRequired'(4)
 *              <div class="title">adoptionMessage</div>
 *          enf if(4)
 *      end foreach (2)
 * end if bookshelf (1)
 * ---done
 *
 * if test="bookstore-response/term/bookshelf/requiredBooks/book"  (5)
 *      <div class="header">Required Items</div>
 *      for-each select="bookstore-response/term/bookshelf/requiredBooks/book"> (6)
 *          book info and picture
 *      end foreach (6)
 * end if (5)
 * --done
 *
 * if test="bookstore-response/term/bookshelf/recommendedBooks/book (7)
 *       <div class="header">Recommended Items</div>
 *        for-each select="bookstore-response/term/bookshelf/recommendedBooks/book"> (8)
 *              book and image
 *        enf forech (8)
 * end if (7)
 *
 * ///done
 *
 * if test="bookstore-response/term/bookshelf/bookChoices/bookChoice/book">(9)
 *      <xsl:for-each select="/bookstore-response/term/bookshelf/bookChoices/bookChoice">(10)
 *          if  test="@isRequired then "Required" else "Recommended" "Choice: Select" <xsl:value-of select="@selectionQuantity"/> of <xsl:value-of select="@maxQuantity"/> end if
 *         for-each select="book"> (11)
 *              book and image
 *
 *          end foreaach (11)
 *       end foreach  (10)
 * enf if (9)
 * //done
 *
 * if test="bookstore-response/term/bookshelf/bundledBooks/bundle/bundledOffering/book">(12)
 *      for-each select="/bookstore-response/term/bookshelf/bundledBooks/bundle">(13)
 *          if test="@isRequired" then "Required" else "Recommended" end if -> "Bundled Offering"
 *          for-each select="bundledOffering/book">(14)
 *              book and image
 *          enf foreaach (14)
 *       end foreach (13)
 * end if (12)
 *
 *
 * if test="/bookstore-response/term/bookshelf/bundledBooks/bundle/requiredBundleBooks/book">(15)
 *      Required Components
 *      for-each select="/bookstore-response/term/bookshelf/bundledBooks/bundle/requiredBundleBooks/book">(16)
 *          book and image
 *      end forech (16)
 * end if (15)
 *
 *
 * if test="/bookstore-response/term/bookshelf/bundledBooks/bundle/recommendedBundleBooks/book"> (17)
 *          Recommended Components
 *          for-each select="/bookstore-response/term/bookshelf/bundledBooks/bundle/recommendedBundleBooks/book" (18)
 *                  book and image
 *          end foreach (18)
 * end if (17)
 *
 *
 * if test="count(bookstore-response/term/bookshelf/requiredBooks/book) = 0 and count(bookstore-response/term/bookshelf/recommendedBooks/book) = 0 and count(bookstore-response/term/bookshelf/bundledBooks/bundle/bundledOffering/book) = 0 and count(bookstore-response/term/bookshelf/bookChoices/bookChoice/book) = 0" (20)
 *      <div style="height:40px;">book list not available</div>
 * end if (20)
 */
