import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faGraduationCap} from "@fortawesome/free-solid-svg-icons";

class ClassBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerHtml: this.props.bannerHtml || "Important information pending.",
            bannerColor: this.props.bannerColor || "gray",
            bannerShow: true
        };

        this.toggleIsVisible = this.toggleIsVisible.bind(this);
    }

    toggleIsVisible() {
        this.setState({
            bannerShow: !this.state.bannerShow
        })
    }

    render() {
        return(<React.Fragment>
            {this.state.bannerShow && (
            <div className="row g-0">
                <div
                    className="col uds-full-width"
                    id="html-root"
                >
                    <section className={"banner-" + this.state.bannerColor}>
                        <div
                            className="banner uds-content-align"
                            role="banner"
                        >
                            <div className="banner-icon">
                                <FontAwesomeIcon icon={faGraduationCap} className="me-2 pt-1" title="Banner"/>
                            </div>
                            <div className="banner-content pt-1">
                                <div dangerouslySetInnerHTML={{__html: this.state.bannerHtml}}/>
                            </div>
                            <div className="banner-buttons">
                                <a
                                    href="https://provost.asu.edu/asu-general-studies-gold"
                                    role="button"
                                    className="btn btn-sm btn-dark"
                                >
                                    Learn more
                                </a>
                            </div>
                            <div className="banner-close">
                                <button
                                  aria-label="Close Banner"
                                  type="button"
                                  className="btn btn-circle btn-circle-alt-black close"
                                  onClick={this.toggleIsVisible}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            )}
        </React.Fragment>)
    }
}

export default ClassBanner;
